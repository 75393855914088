@import "../../scss/vars";

.list-table {
    position: relative;

    .react-datepicker-popper {
        z-index: 9999;
    }

    .filter-by-date {
        margin: 12px 0;
    }

    .list-header {
        @include XGA {
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
        }

        .list-title {
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            column-gap: 16px;

            h1, .btn {
                @include XGA {
                    margin: 0;
                }
            }
        }

        .additional-menu {
            ul {
                list-style: none;
                padding-left: 0;
                display: flex;
                flex-flow: row;
                flex-wrap: nowrap;
                justify-content: flex-end;
                column-gap: 12px;

                li {
                    a {
                        color: #3A3A3A;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .list-top {
        background-color: #fff;
        padding: 16px;
        margin: 12px 0;

        @include XGA {
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
        }

        .search-box {
            position: relative;
        }
    }

    .pagination-outer {
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: center;
        column-gap: 12px;

        .pagination {
            margin: 0;

            li {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px;
                padding: 0;
                cursor: pointer;

                &.disabled {
                    display: none;
                }

                &:hover {
                    text-decoration: underline;
                }
            }

            li.active {
                background-color: #3A3A3A;
                color: #fff;
            }
        }
    }

    .table-outer {
        margin-top: 24px;

        .table-top {
            @include XGA {
                display: flex;
                flex-flow: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: center;
            }

            .tab-filters-list {
                display: flex;
                flex-flow: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                column-gap: 1px;
                list-style: none;
                padding: 0;
                margin: 0;

                .btn {
                    opacity: 0.5;
                    cursor: pointer;
                    margin: 0;

                    span {
                        text-transform: none;
                    }

                    &.active {
                        opacity: 1;
                    }
                }
            }

            .table-options {
                display: flex;
                flex-flow: row;
                flex-wrap: nowrap;
                justify-content: flex-end;
                align-items: center;
                column-gap: 12px;

                .sort-as-user {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: nowrap;
                    justify-content: flex-end;
                    align-items: center;
                    column-gap: 8px;

                    .info-icon {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 22px;
                        min-width: 22px;
                        height: 22px;
                        background-color: #3A3A3A;
                        color: #fff;
                        border-radius: 50%;

                        &:hover {
                            .info-description {
                                display: block;
                            }
                        }

                        .info-description {
                            width: 260px;
                            position: absolute;
                            top: 100%;
                            right: 0;
                            list-style: none;
                            padding: 0;
                            display: none;
                            padding: 16px;
                            background-color: #FFF;
                            color: #3A3A3A;
                            box-shadow: rgba($color: #3A3A3A, $alpha: 0.4) 5px 5px 10px;
                        }
                    }
                }

                .customize-table {
                    position: relative;
                    z-index: 999;

                    .btn {
                        background-color: #fff;
                        cursor: pointer;
                        margin: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        column-gap: 8px;

                        span {
                            color: #3A3A3A;
                            text-transform: none;
                        }
                    }

                    .customize-options {
                        position: absolute;
                        top: 100%;
                        right: 0;
                        list-style: none;
                        padding: 0;
                        display: none;
                        padding: 16px;
                        background-color: #FFF;
                        color: #3A3A3A;
                        z-index:9999;
                        box-shadow: rgba($color: #3A3A3A, $alpha: 0.4) 5px 5px 10px;

                        &.show {
                            display: block;
                            width: 100%;
                            z-index: 100;
                        }
                    }
                }
            }
        }

        .table-inner {
            background-color: #fff;
            overflow-x: auto;
            width: 100%;

            table {
                border-spacing: 0;
                border-collapse: collapse;
                width: 100%;
                min-height: 400px;
            }

            thead {
                background-color: #3A3A3A;
                color: #fff;
                text-align: left;

                tr {
                    th {
                        padding: 12px 24px;
                        border: 1px solid #FFF;
                        background-color: #3A3A3A;

                        .sort-settings {
                            position: relative;
                            cursor: pointer;
                            width: 100%;
                            height: 100%;
                            z-index: 100;

                            &.active {
                                ul {
                                    display: block;
                                }
                            }
                        }

                        button.sort-button {
                            background-color: transparent;
                            border: none;
                            padding: 0;
                            color: #fff;
                            font-weight: 600;
                            cursor: pointer;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            flex-flow: row;
                            flex-wrap: nowrap;
                            justify-content: space-between;
                            align-items: center;
                            column-gap: 6px;
                            white-space: nowrap;

                            &:hover {
                                text-decoration: underline;
                            }

                            svg {
                                opacity: 0.4;
                            }

                            &.active {
                                svg {
                                    opacity: 1;
                                }
                            }

                            &.asc {
                                &:after {
                                    content: "";
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    width: 14px;
                                    height: 8px;
                                    background-color: #3A3A3A;
                                }
                            }

                            &.desc {
                                &:after {
                                    content: "";
                                    position: absolute;
                                    bottom: 0;
                                    right: 0;
                                    width: 14px;
                                    height: 8px;
                                    background-color: #3A3A3A;
                                }
                            }
                        }

                        ul {
                            position: absolute;
                            top: 100%;
                            right: 0;
                            list-style: none;
                            padding: 0;
                            display: none;
                            padding: 16px;
                            background-color: #FFF;
                            color: #3A3A3A;
                            box-shadow: rgba($color: #3A3A3A, $alpha: 0.4) 5px 5px 10px;

                            li {
                                cursor: pointer;
                                white-space: nowrap;
                                padding: 4px 0;

                                &.active, &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
            }

            tbody {
                tr {
                    &:hover {
                        background-color: rgba($color: #3A3A3A, $alpha: 0.2);
                    }

                    td {
                        white-space: nowrap;
                        position: relative;
                        padding: 12px 24px;
                        border: 1px solid #EAECF0;

                        img {
                            max-width: 60px;
                        }

                        .options {
                            position: absolute;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            text-align: center;
                            cursor: pointer;

                            &:hover {
                                background-color: #3A3A3A;

                                svg {
                                    path {
                                        fill: #fff;
                                    }
                                }
                            }

                            button {
                                background-color: transparent;
                                border: none;
                            }

                            ul {
                                margin: 0;
                                position: absolute;
                                bottom: 0;
                                right: 100%;
                                list-style: none;
                                padding: 0;
                                display: none;
                                padding: 16px;
                                background-color: #FFF;
                                color: #3A3A3A;
                                z-index: 100;
                                box-shadow: rgba($color: #3A3A3A, $alpha: 0.4) 5px 5px 10px;
    
                                li {
                                    cursor: pointer;
                                    white-space: nowrap;
                                    padding: 4px 0;
                                    text-align: left;
                                    display: flex;
                                    flex-flow: row;
                                    justify-content: flex-start;
                                    align-items: center;
                                    column-gap: 8px;
    
                                    &.active, &:hover {
                                        text-decoration: underline;
                                    }
                                }
                            }

                            &:hover {
                                ul {
                                    display: block;
                                }
                            }
                        }

                        .flags {
                            padding: 0;
                            margin: 0;
                            list-style: none;
                            display: flex;
                            flex-flow: row;
                            flex-wrap: nowrap;
                            justify-content: flex-start;
                            align-items: center;
                            column-gap: 8px;
                            row-gap: 8px;

                            li {
                                position: relative;
                                padding: 4px 8px;
                                padding-left: 18px;
                                border-radius: 16px;
                                background-color: #F2F4F7;
                                color: #364254;

                                &::before {
                                    content: "";
                                    position: absolute;
                                    left: 8px;
                                    top: 11px;
                                    width: 6px;
                                    height: 6px;
                                    border-radius: 50%;
                                    background-color: #364254;
                                }

                                &.published {
                                    background-color: #ECFDF3;
                                    color: #037847;

                                    &::before {
                                        background-color: #037847;
                                    }
                                }

                                &.internal {
                                    background-color: #FFF1D2;
                                    color: #F5A905;

                                    &::before {
                                        background-color: #F5A905;
                                    }
                                }

                                &.contest {
                                    background-color: #E7E7FF;
                                    color: #696CFF;

                                    &::before {
                                        background-color: #696CFF;
                                    }
                                }
                            }
                        }
                    }

                    &:first-child {
                        td {
                            .options {
                                ul {
                                    bottom: auto;
                                    top: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .list-footer {
        margin: 16px 0;
        margin-bottom: 36px;
    }
}